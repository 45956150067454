import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FigmaPage from './pages/FigmaPage';
import AskPage from './pages/AskPage';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/OSUkyDlRRYv6c0LJd70VTGEGqwYYgchEOT0ewW8OKL1l3MzCvQUexrT3OtvxVogezYdyVRdKqa1H27GDZL94iyE7EezcRuMZV8UojRMvDgRUgBKZumCgyasfRRkTWhCGgxfYFFzlMv2mmLwEGKUCvIBHsYz5JK2w2wladrrswSX90uA8cD65wFHZXm9xLUetODTZerDKxkLucal0KezgasOThpD3laQa4ZzmdGZY9aPMhg6budWfi58hcdwocM1HfFpX4VxTJFHYPxVEsl1eMRy8AFRZRSga3qUvTHuj" element={<FigmaPage />} />
        <Route path="/ask/workelo/Z3r1fyhUweVqgsiIcePeU5Tgy4dtBxvOQqkkvF902YWZFkod7R1jIHRZHthZkse3ALcYMWAGIQdp6EuDQv56byHARtvYYYgWEJuYl1gdxEETAzhmQ3cAu6SuyLq6xG4isPSurI53SjMfyPeL0FaZoBYjid80slOMKPGvXjuQsW5cdTF5XRC6CFs12LSH8RTdft5xvgGvF1y7Pe3G8imbVLXI3CBuda45BSHC6fJKY5OrqRM" element={<AskPage />} />
      </Routes>
    </Router>
  );
};

export default App;
