import React, { useState } from 'react';
import { Input, Spin, List, Typography } from 'antd';
import axios from 'axios';

const { Search } = Input;
const { Text } = Typography;

const AskPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [qaList, setQaList] = useState<{ question: string, answer: string }[]>([]);

  const handleSearch = async (value: string) => {
    setLoading(true);
    try {
      const response = await axios.post('https://backendrag-aa83ea144f84.herokuapp.com/ask', {
        question: value,
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const newQa = { question: value, answer: response.data.answer };
      setQaList(prevList => [newQa, ...prevList]);
    } catch (error) {
      const newQa = { question: value, answer: 'An error occurred. Please try again.' };
      setQaList(prevList => [newQa, ...prevList]);
    }
    setLoading(false);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh', padding: '10% 0' }}>
      <div style={{ display: 'flex', alignItems: 'center', width: '60%' }}>
        <Search
          placeholder="Ask a question"
          enterButton="Ask"
          size="large"
          onSearch={handleSearch}
          style={{ flex: 1 }}
        />
        {loading && <Spin style={{ marginLeft: 10 }} />}
      </div>
      <div style={{ marginTop: 20, width: '60%', flex: 1, overflowY: 'auto' }}>
        <List
          bordered
          dataSource={qaList}
          renderItem={item => (
            <List.Item>
              <div style={{ width: '100%' }}>
                <Text strong>Question:</Text> {item.question}
                <br />
                <Text strong>Answer:</Text> <Text copyable>{item.answer}</Text>
              </div>
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};

export default AskPage;
